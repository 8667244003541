@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');

#root {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  max-width: 1920px;
  min-height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  font-family: var(--font-family);
  color: var(--dark-color);
  background: var(--bg-gradient);
  padding: 64px 16px 32px;
}


::-webkit-scrollbar{
  display: none;
}
